<ng-container *ngIf="userForm">
	<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
		<mat-tab label="Profile">
			<form [formGroup]="userForm" class="text-dark m-0 p-0" (submit)="updateUser()">
				<div class="row m-0 p-0 mt-4">
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>User Id</mat-label>
							<input matInput formControlName="userid">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>Password</mat-label>
							<input matInput formControlName="password">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>Name</mat-label>
							<input matInput formControlName="realname">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="full-width">
							<input matInput formControlName="email" placeholder="Email address" type="email">
						</mat-form-field>
					</div>
				</div>
				<div class="row  m-0 p-0">
					<div class="col-md-4">
						<mat-form-field appearance="outline" class="">
							<mat-label>Default Location</mat-label>
							<mat-select formControlName="defaultlocation">
								<mat-option *ngFor="let loc of alllocations" [value]="loc.loccode">
									{{loc.locationname}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field class="full-width" appearance="outline">
							<mat-label>Phone#</mat-label>
							<input matInput placeholder="Phone" type="text" formControlName="phone">
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>Employee Code</mat-label>
							<input matInput placeholder="Employee Code" formControlName="empcode" type="text">
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>Department</mat-label>
							<input matInput placeholder="Department" formControlName="department" type="text">
						</mat-form-field>
					</div>
				</div>
				<div class="row  m-0 p-0">
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="full-width">
							<mat-label>Access Level</mat-label>
							<input matInput placeholder="Full Access" formControlName="fullaccess" type="text">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="">
							<mat-label>User Group</mat-label>
							<mat-select formControlName="user_group">
								<mat-option *ngFor="let yn of usergroups" [value]="yn.user_group_id">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row  m-0 p-0">
					<div class="col-md-3">
						<mat-form-field appearance="outline" class="">
							<mat-label>Customer Admin</mat-label>
							<mat-select formControlName="customeradmin">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>

					</div>
					<div class="col-md-3">

						<mat-form-field appearance="outline" class="">
							<mat-label>Create Tender</mat-label>
							<mat-select formControlName="cancreatetender">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-3">

						<mat-form-field appearance="outline" class="">
							<mat-label>Disabled</mat-label>
							<mat-select formControlName="blocked">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>


					<div class="col-md-3 mb-3">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>View Cost</mat-label>
							<mat-select formControlName="viewcost">
								<mat-option [value]="'0'">N</mat-option>
								<mat-option [value]="'1'">Y</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-3">

						<mat-form-field appearance="outline" class="">
							<mat-label>Edit Invoice</mat-label>
							<mat-select formControlName="editinv">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-3">
						<mat-form-field appearance="outline" class="">
							<mat-label>Dispatch Admin</mat-label>
							<mat-select formControlName="dispatchadmin">
								<mat-option *ngFor="let yn of yesno" [value]="yn.value">
									{{yn.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-slide-toggle class="mt-3" [formControl]="isSalesmanToggle">Is Salesman?</mat-slide-toggle>
					</div>
					<div class="col-md-3">
						<mat-form-field appearance="outline" class="mt-2 full-width" *ngIf="isSalesmanToggle.value">
							<mat-label>Salesman Code</mat-label>
							<input matInput placeholder="Salesman Code" formControlName="salesman" type="text">
						</mat-form-field>
					</div>
				</div>
				<div class="row d-none">
					<div class="col-md-12">
						<label>About Me</label>
						<mat-form-field class="full-width">
							<textarea matInput placeholder=""></textarea>
						</mat-form-field>
					</div>
				</div>



				<a href="javascript:void(0)" class="profile-plugin switch-trigger active-color">
					<div class="ml-auto mr-auto">
						<span class="badge filter badge-purple" data-color="purple" (click)="changColor('purple')"></span>
						<span class="badge filter badge-azure" data-color="azure" (click)="changColor('azure')"></span>
						<span class="badge filter badge-green" data-color="green" (click)="changColor('green')"></span>
						<span class="badge filter badge-orange" data-color="orange" (click)="changColor('orange')"></span>
						<span class="badge filter badge-danger active" data-color="danger" (click)="changColor('danger')"></span>
					</div>
					<div class="clearfix"></div>
					<div class="ripple-container"></div>
				</a>
				<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Profile</button>
				<div class="clearfix"></div>
			</form>
		</mat-tab>


		<mat-tab label="Salesman Details" *ngIf="isSalesmanToggle.value">
			<form [formGroup]="salesForm" class="mt-3">
				<div class="row">
					<div class="col-md-6">
						<div class="card mb-3">
							<div class="card-header">Contact Info</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Manager</mat-label>
											<mat-select formControlName="manager">
												<ng-container *ngFor="let g of managers">
													<mat-option [value]="g.salesmanname">{{ g.salesmanname }}</mat-option>
												</ng-container>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Salesperson Code</mat-label>
											<input matInput required formControlName="salesmancode" placeholder="Enter Salesperson Code" value="12345">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Salesperson Name</mat-label>
											<input matInput required formControlName="salesmanname" placeholder="Enter Salesperson Name" value="John Doe">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Address</mat-label>
											<input matInput formControlName="smanaddr" placeholder="Enter Address" value="123 Main St">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<mat-form-field appearance="outline">
											<mat-label>City</mat-label>
											<input matInput formControlName="smancity" placeholder="Enter City" value="Anytown">
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="outline">
											<mat-label>Region</mat-label>
											<mat-select formControlName="smanstate">
												<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
													{{zone.code }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="outline">
											<mat-label>Zip</mat-label>
											<input matInput formControlName="smanzip" placeholder="Enter Zip" value="12345">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<mat-form-field appearance="outline">
											<mat-label>Tablet Number</mat-label>
											<input matInput formControlName="smantab" placeholder="Enter Tablet Number" value="555-1234">
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
						<div class="card mb-3">
							<div class="card-header">Commission Info</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Commission Rate 1</mat-label>
											<input matInput formControlName="commissionrate1" placeholder="Enter Commission Rate 1" value="10%">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Commission Rate 2</mat-label>
											<input matInput formControlName="commissionrate2" placeholder="Enter Commission Rate 2" value="5%">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Commission Break Point</mat-label>
											<input matInput formControlName="breakpoint" placeholder="Enter Commission Break Point" value="1000">
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card mb-3">
							<div class="card-header">Control Info</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>SalePerson Type</mat-label>
											<mat-select formControlName="group_level">
												<ng-container *ngFor="let g of grouplevels">
													<mat-option [value]="g.value">{{ g.name }}</mat-option>
												</ng-container>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Include in Reports</mat-label>
											<mat-select formControlName="group_id">
												<mat-option value="1">Yes</mat-option>
												<mat-option value="2">No</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="outline">
											<mat-label>Enabled</mat-label>
											<mat-select formControlName="current">
												<mat-option value="1">Yes</mat-option>
												<mat-option value="0">No</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

							</div>
						</div>
						<div class="card mb-3" *ngIf="getIsManager()">
							<div class="card-header">Sales People Assigned</div>
							<div class="card-body">
								<div class="row">
									<mat-list>
										<div class="row">
											<div class="col-md-6" *ngFor="let g of salespeople">
												<mat-list-item>
													<mat-checkbox [checked]="salespeopleArray.includes(g.salesmancode)" (change)="toggleCheckbox(g.salesmancode)">{{ g.salesmanname }}</mat-checkbox>
												</mat-list-item>
											</div>
										</div>
									</mat-list>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<button mat-stroked-button color="primary" (click)="saveSalesPerson()">
						<mat-icon>save</mat-icon> Save
					</button>
				</div>
			</form>
		</mat-tab>


		<mat-tab label="Location Access">
			<div class="row  m-0 p-0 mt-4">
				<div class="col-md-6">
					<h5>Locations Access</h5>
					<mat-divider></mat-divider>
					<mat-action-list class="borderd perm-box mt-2">
						<ng-container *ngFor="let loc of alllocations">
							<button mat-list-item class="mat-list-item-bordered" *ngIf="!hasLocation(loc.loccode)" (click)="addUserLocation(loc)">
								<span>
									{{ loc.loccode }} - {{ loc.locationname }}
								</span>
								<span class="ml-auto mr-0"><small>
										<mat-icon color="accent" class="small bold">add</mat-icon>
									</small></span>
							</button>
						</ng-container>
					</mat-action-list>
				</div>
				<div class="col-md-6">
					<h5>Access</h5>
					<mat-divider></mat-divider>
					<mat-action-list class="borderd perm-box mt-2">
						<button mat-list-item class="mat-list-item-bordered" *ngFor="let loc of user.locations" (click)="removeUserLocation(loc)">
							<span>
								{{ loc.loccode }} - {{ loc.locationname }}
							</span>
							<span class="ml-auto mr-0"><small>
									<mat-icon color="accent" class="small bold">remove</mat-icon>
								</small></span>
						</button>
					</mat-action-list>
				</div>

				<button mat-raised-button class="btn btn-danger pull-right" (click)="updateLocations()">Update Locations</button>
			</div>
		</mat-tab>
		<mat-tab label="Printers">
		  <div class="row m-0 p-0 mt-4">

			<!-- Available Devices Section -->
			<div class="col-md-6">
			  <div class="section-header d-flex justify-content-between align-items-center">
				<h5 class="mat-title">Available Devices</h5>
				<a href="https://api.beautyserv.com/PrintConnectSetup1.4.6.zip" class="download-link">Download Print Connect</a>
			  </div>
			  <mat-divider></mat-divider>

			  <!-- Search Box for Available Devices -->
			  <mat-form-field appearance="outline" class="search-field w-100 mt-3">
				<mat-label>Search Available Devices</mat-label>
				<input matInput [(ngModel)]="searchAvailableDevices" placeholder="Search devices">
				<button mat-icon-button matSuffix (click)="searchAvailableDevices = ''" matTooltip="Clear search">
				  <mat-icon>clear</mat-icon>
				</button>
			  </mat-form-field>

			  <!-- Available Devices as Cards -->
			  <div *ngIf="filteredAvailableDevices().length === 0" class="text-center mt-4">
				<p class="text-muted">No printers available.</p>
			  </div>

			  <div class="row mt-3">
				<div class="col-sm-12" *ngFor="let device of filteredAvailableDevices()">
				  <mat-card class="device-card mb-3">
					<div class="d-flex justify-content-between align-items-center card-content">
					  <!-- Device Name and IP on the Left -->
					  <div class="device-info">
						<mat-card-title>{{ device.machine_name }}</mat-card-title>
						<mat-card-subtitle>{{ device.ip }}</mat-card-subtitle>
					  </div>

					  <!-- Device Types Selector and Add Button on the Right -->
					 <div class="device-actions d-flex align-items-center">
						 <mat-form-field appearance="outline" class="device-type-selector mr-2 full-width">
							 <mat-label>Device Types</mat-label>
							 <mat-select multiple [(ngModel)]="selectedDeviceTypes[device.device_id + '_' + device.machineName]" placeholder="Select device types">
								 <mat-option *ngFor="let type of deviceTypes" [value]="type">{{ type }}</mat-option>
							 </mat-select>
						 </mat-form-field>
						 <button mat-raised-button color="primary" class="ml-2" (click)="addUserDevice(device)" matTooltip="Add Device">
							 <mat-icon>add</mat-icon>
						 </button>
					 </div>
					</div>
				  </mat-card>
				</div>
			  </div>
			</div>

			<!-- User Devices Section -->
			<div class="col-md-6">
			  <h5 class="mat-title">User Devices</h5>
			  <mat-divider></mat-divider>

			  <!-- Search Box for User Devices -->
			  <mat-form-field appearance="outline" class="search-field w-100 mt-3">
				<mat-label>Search User Devices</mat-label>
				<input matInput [(ngModel)]="searchUserDevices" placeholder="Search user devices">
				<button mat-icon-button matSuffix (click)="searchUserDevices = ''" matTooltip="Clear search">
				  <mat-icon>clear</mat-icon>
				</button>
			  </mat-form-field>

			  <!-- User Devices as Cards -->
			  <div *ngIf="filteredUserDevices().length === 0" class="text-center mt-4">
				<p class="text-muted">No user printers set.</p>
			  </div>

			  <div class="row mt-3">
				<div class="col-sm-12" *ngFor="let device of filteredUserDevices()">
				  <mat-card class="device-card mb-3">
					<div class="d-flex justify-content-between align-items-center card-content">
					  <!-- Device Name and IP on the Left -->
					  <div class="device-info">
						<mat-card-title>{{ device.machineName }}</mat-card-title>
						<mat-card-subtitle>{{ device.ip }}</mat-card-subtitle>
					  </div>

					  <!-- Print Types Selector and Remove Button on the Right -->
					  <div class="device-actions d-flex align-items-center">
						<mat-form-field appearance="outline" class="device-type-selector mr-2">
						  <mat-label>Print Types</mat-label>
						  <mat-select multiple [(ngModel)]="device.deviceTypes" (selectionChange)="updatePrintSettings(device.deviceID, device.deviceTypes)" placeholder="Select print types">
							<mat-option *ngFor="let type of deviceTypes" [value]="type">{{ type }}</mat-option>
						  </mat-select>
						</mat-form-field>
						<button mat-raised-button color="warn" (click)="removeUserDevice(device)" matTooltip="Remove Device">
						  Remove
						</button>
					  </div>
					</div>
				  </mat-card>
				</div>
			  </div>
			</div>
		  </div>
		</mat-tab>





	</mat-tab-group>

</ng-container>